import { Box } from '@chakra-ui/react';
import { Hour } from 'models/apiModels';
import { Moment } from 'moment';
import React from 'react';
import useSWR, { Fetcher } from 'swr';
import { getHoursMonth } from 'utils/api';

const fetcher: Fetcher<Hour[], [string, string, number, number]> = ([, user_id, month, year]: [
    string,
    string,
    number,
    number,
]) => getHoursMonth(user_id, month, year);

const CalendarPreFetch: React.FC<{ date: Moment; user_id: number }> = ({ date, user_id }) => {
    const month = date.get('month') + 1;
    const year = date.get('year');

    const {} = useSWR(['/api/user', user_id.toString(), month, year], fetcher);

    return <Box hidden={true}></Box>;
};

export default CalendarPreFetch;
