import React, { useState } from 'react';
import FullFlex from 'components/atoms/FullFlex';
import HourCalendar from 'components/molecules/HourCalendar';
import HourAccordionItem from 'components/organism/HourAccordionItem';
import moment from 'moment';
import { Accordion } from '@chakra-ui/react';
import { useHours } from 'hooks/useHours';
import NavBar from 'components/organism/NavBar';
import { useUser } from 'hooks/useUser';

const OverviewPage: React.FC = () => {
    const [selectedDay, setSelectedDay] = useState(moment());
    const user = useUser();
    const month = selectedDay.get('month') + 1;
    const year = selectedDay.get('year');
    const { data: hours } = useHours(month, year);

    if (user.isLoading || user.data == undefined) {
        return null;
    }

    return (
        <FullFlex align={['flex-start', 'center']} flexDir="column">
            <NavBar />
            <HourCalendar userId={user.data.id} onCellSelect={setSelectedDay} />
            <Accordion defaultIndex={[0]} reduceMotion>
                <HourAccordionItem
                    key={`${selectedDay.dayOfYear()}`}
                    showLock={true}
                    date={selectedDay.clone().utc(true)}
                    entry={hours?.find((item) =>
                        moment(item.date).utc(true).isSame(selectedDay.clone().utc(true), 'date'),
                    )}
                />
            </Accordion>
        </FullFlex>
    );
};

export default OverviewPage;
