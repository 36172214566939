/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
    Authenticate,
    Hour,
    HourProject,
    HourStatus,
    HourSummary,
    Project,
    ProjectSummary,
    ProjectSummaryDetail,
    User,
} from 'models/apiModels';
import { post, postForm, postJSON } from './api/post';
import { get } from './api/get';
import { put } from './api/put';
import { del } from './api/del';

/* Endpoints */
export const getUser = (): Promise<User> => get<User>('/users/me', true);
export const getUsers = (): Promise<User[]> => get<User[]>('/users', true);
export const getProjects = (): Promise<Project[]> => get<Project[]>('/projects', true);
export const getProjectsSummary = ([, startMonth, startYear, endMonth, endYear]: [
    string,
    number,
    number,
    number,
    number,
]): Promise<ProjectSummary[]> =>
    get<ProjectSummary[]>(
        `/projects/summary?start_month=${startMonth}&start_year=${startYear}&end_month=${endMonth}&end_year=${endYear}`,
        true,
    );
export const getProjectSummaryDetail = ([, id, startMonth, startYear, endMonth, endYear]: [
    string,
    number,
    number,
    number,
    number,
    number,
]): Promise<ProjectSummaryDetail[]> =>
    get<ProjectSummaryDetail[]>(
        `/projects/summary/${id}?start_month=${startMonth}&start_year=${startYear}&end_month=${endMonth}&end_year=${endYear}`,
        true,
    );
export const getHours = (): Promise<Hour[]> => get<Hour[]>('/users/hours', true);
export const getHoursMonth = (user_id: string, month: number, year: number): Promise<Hour[]> =>
    get<Hour[]>(`/hours/user/${user_id}?month=${month}&year=${year}`, true);
export const getHoursWeek = ([, week, year]: [string, number, number]): Promise<Hour[]> =>
    get<Hour[]>(`/hours/week?week=${week}&year=${year}`, true);
export const getHoursMonthSummary = ([, month, year]: [string, number, number]): Promise<HourSummary[]> =>
    get<HourSummary[]>(`/hours/summary?month=${month}&year=${year}`, true);
export const getHoursStatus = (): Promise<HourStatus[]> => get<HourStatus[]>(`/hours/status`, true);

/* Post Endpoints */
export const postHour = (
    date: Date,
    hours: number,
    user_id: number,
    lunch: number,
    comment: string,
    hour_projects: HourProject[],
): Promise<Hour> =>
    postJSON<Hour, Omit<Hour, 'id' | 'locked'>>(
        '/hours/me',
        {
            date: date.toISOString(),
            hours,
            lunch,
            comment,
            user_id,
            hour_registration_projects: hour_projects.map((registration) => ({
                ...registration,
                project_id: parseInt(registration.project_id as unknown as string),
            })),
        },
        true,
    );
export const lockHours = (user_id: number, week: number, year: number): Promise<number> =>
    post<number>(`/hours/lock/${user_id}/${week}/${year}`, true);
export const lockHour = (id: number): Promise<never> => post<never>(`/hours/lock/${id}`, true);
export const postUser = (name: string, email: string): Promise<User> =>
    postJSON<User, Pick<User, 'name' | 'email'>>('/users', { name, email }, true);
export const postActivateUser = (token: string, password: string): Promise<never> =>
    postJSON<never, { token: string; password: string }>('/users/activate', { token, password }, false);
export const postRecoverPassword = (email: string) =>
    postJSON<string, null>('/users/password-recovery/' + email, null, true);
export const postResendActivationEmail = (user_id: number) =>
    postJSON<never, null>(`/users/${user_id}/resend-activation-email`, null, true);
export const postResetPassword = (token: string, password: string): Promise<never> =>
    postJSON<never, { token: string; new_password: string }>('/users/reset-password', {
        token,
        new_password: password,
    });

export const postProject = (name: string): Promise<{ name: string }> =>
    postJSON<Project, { name: string }>('/projects', { name }, true);

export const authenticate = (username: string, password: string): Promise<Authenticate> =>
    postForm<Authenticate>('/auth/access-token', { username, password }, false);
export const renewToken = (refresh_token: string): Promise<Authenticate> =>
    postJSON<Authenticate, string>('/auth/refresh-token', refresh_token);
export const validateToken = (): Promise<never> => get<never>('/auth/validate-token', true);
export const logout = (refresh_token: string) => postJSON<never, string>('/auth/logout', refresh_token);

/* Put Endpoints */
export const putHour = (hour: Partial<Hour>): Promise<Hour> =>
    put<Hour, Partial<Hour>>(`/hours/${hour.id!}`, hour, true);
export const putHourAdmin = (hour: Partial<Hour>): Promise<Hour> =>
    put<Hour, Partial<Hour>>(`/hours/${hour.id!}/admin`, hour, true);
export const putUser = (user: Partial<User>): Promise<User> =>
    put<User, Partial<User>>(`/users/${user.id}`, user, true);
export const putProject = (project: Partial<Project>): Promise<Project> =>
    put<Project, Partial<Project>>(`/projects/${project.id}`, project, true);

/* Delete Endpoints */
export const deleteHourPick = (id: number) => del(`/hours/projects/${id}`, true);
export const deleteHour = (id: number) => del(`/hours/${id}`, true);
