/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import {
    Box,
    Button,
    ButtonGroup,
    Flex,
    FormControl,
    FormErrorMessage,
    HStack,
    IconButton,
    Stack,
} from '@chakra-ui/react';
import SBInput from 'components/atoms/textfield/SBInput';
import SBProjectSelect from './SBProjectSelect';
import { Form, withFormik, FormikProps, FormikErrors } from 'formik';
import { hourValidator, lunchValidator } from 'utils/validators';
import { Moment } from 'moment';
import { deleteHour, deleteHourPick, lockHour, postHour, putHour, putHourAdmin } from 'utils/api';
import { mutate } from 'swr';
import { Hour, HourProject } from 'models/apiModels';
import { DeleteIcon } from '@chakra-ui/icons';
import { useUser } from 'hooks/useUser';
import LockIcon from 'components/atoms/lock/Lock';
import SBConfirmActionButton from 'components/atoms/SBConfirmActionButton';

interface HourFormValues {
    hours: number;
    lunch: number;
    comment: string;
    hour_projects: HourProject[];
    locked?: boolean;
}

interface InnerHourFormProps extends Partial<Omit<Hour, 'date' | 'user_id'>> {
    user_id?: number;
    month?: number;
    year?: number;
    week?: number;
}

const InnerHourForm: React.FC<InnerHourFormProps & FormikProps<HourFormValues>> = ({
    isSubmitting,
    touched,
    values,
    errors,
    handleChange,
    setFieldValue,
    week,
    month,
    year,
    resetForm,
    user_id,
    id,
}) => {
    const { data } = useUser();
    const onAddProject = () => {
        const temp = { name: 'hour_projects', value: [...values.hour_projects, { hours: -1, project_id: -1 }] };
        handleChange({ target: temp });
    };

    const onDeleteProject = (project: HourProject, i: number) => {
        if (project.id) {
            deleteHourPick(project.id);
        }

        handleChange({
            target: { name: 'hour_projects', value: values.hour_projects.filter((_, idx) => idx != i) },
        });
    };

    const hour_project_errors = errors.hour_projects as FormikErrors<HourProject>[] | undefined;

    const disabeled = values.locked || isSubmitting;

    const mutateHours = () => {
        mutate(['/api/user', `${user_id!}`, month, year]);
        mutate(['/api/hours/summary', month, year]);
        mutate(['/api/hours/user/week/', week, year]);
    };

    const onDelete = async () => {
        await deleteHour(id!);
        mutateHours();
        resetForm();
    };

    const onLockPress = async () => {
        if (!data?.is_superuser && id !== undefined) {
            await lockHour(id);
        } else {
            await putHourAdmin({ id: id, locked: !values.locked });
        }
        setFieldValue('locked', !values.locked);
        mutateHours();
    };

    return (
        <Flex flexDirection="column">
            <Form>
                <Stack spacing={4}>
                    {values.hour_projects.map((item, i) => {
                        const hoursTouched = touched?.hour_projects?.[i]?.hours;
                        const projectTouched = touched?.hour_projects?.[i]?.project_id;
                        return (
                            <Box key={`project-${i}`}>
                                <FormControl
                                    isInvalid={
                                        (hour_project_errors ? hour_project_errors[i].hours : undefined) != undefined ||
                                        ((hour_project_errors ? hour_project_errors[i].project_id : undefined) !=
                                            undefined &&
                                            (hoursTouched || projectTouched))
                                    }
                                >
                                    <HStack>
                                        <Box w={125}>
                                            <SBInput
                                                type="number"
                                                step="0.5"
                                                placeholder="f.eks 7.5"
                                                label="Timer"
                                                name={`hour_projects.${i}.hours`}
                                                value={item.hours == -1 ? '' : item.hours}
                                                onChange={handleChange}
                                                isDisabled={disabeled}
                                            />
                                        </Box>
                                        <Box flex="3">
                                            <SBProjectSelect
                                                name={`hour_projects.${i}.project_id`}
                                                onChange={handleChange}
                                                showDisabeled={values.locked}
                                                value={item.project_id == -1 ? undefined : item.project_id}
                                                isDisabled={disabeled}
                                            />
                                        </Box>
                                        <Box alignSelf="flex-end" hidden={i == 0}>
                                            <IconButton
                                                colorScheme="red"
                                                aria-label="Call Segun"
                                                justifySelf="flex-end"
                                                isDisabled={values.locked}
                                                onClick={() => onDeleteProject(item, i)}
                                                icon={<DeleteIcon />}
                                            />
                                        </Box>
                                    </HStack>
                                    <FormErrorMessage hidden={!projectTouched}>
                                        {hour_project_errors ? hour_project_errors[i].project_id : ''}
                                    </FormErrorMessage>
                                    <FormErrorMessage hidden={!hoursTouched}>
                                        {hour_project_errors ? 'Timer: ' + hour_project_errors[i].hours : ''}
                                    </FormErrorMessage>
                                </FormControl>
                            </Box>
                        );
                    })}
                    <Button onClick={onAddProject} isDisabled={!user_id || values.locked} colorScheme="blue">
                        Legg til prosjekt
                    </Button>

                    <SBInput
                        formControl={{ isInvalid: errors.lunch != undefined && touched.lunch }}
                        type="number"
                        placeholder="f.eks 0.5"
                        label="Lunsj"
                        step="0.5"
                        name="lunch"
                        onChange={handleChange}
                        value={values.lunch == -1 ? '' : values.lunch}
                        error={errors.lunch}
                        isDisabled={disabeled}
                    />
                    <SBInput
                        value={values.comment}
                        name="comment"
                        onChange={handleChange}
                        placeholder="Skriv kommentar..."
                        label="Kommentar"
                        isDisabled={disabeled}
                    />
                    <ButtonGroup width="full" spacing="2">
                        <Button
                            flex={1}
                            isDisabled={!user_id || values.locked}
                            isLoading={isSubmitting}
                            colorScheme="blue"
                            type="submit"
                            loadingText={'Lagrer'}
                        >
                            {id ? 'Oppdater timer' : 'Lagre timer'}
                        </Button>
                        {id && (
                            <SBConfirmActionButton
                                isDisabled={!user_id || values.locked}
                                heading="Slett timeføring"
                                body="Er du sikker på at du vil slette timeføringen?"
                                onConfirm={onDelete}
                                aria-label="Slett timeføring"
                                icon={<DeleteIcon />}
                            />
                        )}
                    </ButtonGroup>
                    {id && user_id && ((user_id == data?.id && !values.locked) || data?.is_superuser) && (
                        <Button
                            onClick={onLockPress}
                            variant="solid"
                            colorScheme="blue"
                            leftIcon={<LockIcon locked={values.locked ?? false} />}
                            loadingText={'Lagrer'}
                        >
                            {values.locked ? 'Lås opp' : 'Lås'}
                        </Button>
                    )}
                </Stack>
            </Form>
        </Flex>
    );
};

interface HourFormProps extends InnerHourFormProps {
    date: Moment;
    isAdmin?: boolean;
}

const HourForm = withFormik<HourFormProps, HourFormValues>({
    mapPropsToValues: ({
        week,
        hours,
        user_id,
        month,
        locked,
        year,
        lunch,
        hour_registration_projects: hour_projects,
        comment,
    }) => ({
        hours: hours ?? -1,
        lunch: lunch ?? -1,
        hour_projects: hour_projects && hour_projects?.length > 0 ? hour_projects : [{ project_id: -1, hours: -1 }],
        user_id: user_id,
        comment: comment ?? '',
        month: month,
        week: week,
        locked: locked,
        year: year,
    }),
    enableReinitialize: true,
    validate: (values: HourFormValues) => {
        const errors: FormikErrors<HourFormValues> = {};
        const errorsHourProjects = values.hour_projects.map((item) => ({
            hours: hourValidator(item.hours == -1 ? undefined : item.hours),
            project_id: item.project_id != -1 ? undefined : 'Du må velge prosjekt',
        }));
        errors.hour_projects = errorsHourProjects.some(
            (item) => item.hours != undefined || item.project_id != undefined,
        )
            ? errorsHourProjects
            : undefined;
        errors.lunch = lunchValidator(values.lunch == -1 ? undefined : values.lunch);

        const hasErros = Object.values(errors).some((x) => x !== undefined);
        return hasErros ? errors : {};
    },
    handleSubmit: async (values, { props, setSubmitting, setFieldValue }) => {
        setSubmitting(true);
        const hours = values.hour_projects.reduce((curr, item) => item.hours + curr, 0);
        const { date, isAdmin, ...rest } = props;

        let hour;
        const updateRequest = {
            ...rest,
            ...values,
            hours: hours,
            hour_registration_projects: values.hour_projects.map((item) => ({
                ...item,
                project_id: parseInt(item.project_id.toString()),
            })),
        };
        if (props.id !== undefined && isAdmin) {
            hour = await putHourAdmin(updateRequest);
        } else if (props.id !== undefined) {
            hour = await putHour(updateRequest);
        } else {
            hour = await postHour(
                props.date.toDate(),
                hours,
                props.user_id!,
                values.lunch,
                values.comment,
                values.hour_projects,
            );
        }
        setFieldValue('hour_projects', hour.hour_registration_projects);
        const month = props.date.get('month') + 1;
        const week = props.date.get('week');
        const year = props.date.get('year');
        mutate(['/api/user', `${props.user_id!}`, month, year]);
        mutate(['/api/hours/user/week/', week, year]);
    },
})(InnerHourForm);

export default HourForm;
